<template>
  <v-row class="align-center d-flex h-100 mt-md-n16">
    <v-col lg="4" md="7" class="mx-auto">
      <v-card class="card-shadow border-radius-xl py-1">
        <div
          class="
            card-padding
            text-center
            mt-n7
            bg-gradient-primary
            shadow-primary
            border-radius-lg
            mx-4
          "
        >
          <h4 class="text-h4 font-weight-bolder text-white mb-2">Sign In</h4>
          <p class="mb-1 text-white font-weight-light text-sm">
            Enter your email and password to sign in
          </p>
        </div>
        <v-form
          class="card-padding text-center"
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-text-field
            label="Email"
            color="#e91e63"
            outlined
            dense
            hide-details
            class="font-size-input input-style mb-5"
            v-model="email"
            :rules="emailRules"
            required
            @keyup="show_submit()"
            @paste="show_submit()"
          ></v-text-field>

          <v-text-field
            label="Password"
            color="#e91e63"
            outlined
            hide-details
            dense
            :append-icon="show ? 'visibility' : 'visibility_off'"
            @click:append="show = !show"
            :type="show ? 'text' : 'password'"
            required
            @keyup="show_submit()"
            @paste="show_submit()"
            class="font-size-input input-style mb-5"
            v-model="password"
          ></v-text-field>

          <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            class="
              btn-default
              bg-gradient-primary
              py-2
              px-6
              me-2
              mt-6
              mb-2
              w-100
            "
            color="#5e72e4"
            small
            @click="submit"
            :disabled="disabled"
          >
            <p v-if="!progressCircle" class="mt-4 
            font-weight-bold text-uppercase" style="font-size: 14px">
              Sign In
            </p>
            <v-progress-circular
              v-else
              :width="3"
              :size="25"
              indeterminate
              color="white"
            ></v-progress-circular>
          </v-btn>
          <router-link to="/sign-up" class="d-block mt-2">
            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                btn-default
                bg-gradient-secondary
                py-2
                px-6
                w-100
              "
              color="#5e72e4"
              small
            >
              <p class="mt-4 font-weight-bold text-uppercase" style="font-size: 14px">
                Register
              </p>
            </v-btn>
          </router-link>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import Vue from "vue";

export default {
  data() {
    return {
      progressCircle: false,
      show: false,
      showError: false,
      errorMessage: "",
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      password: "",
      disabled: true,
      valid: false,
    };
  },
  mounted() {
    document.title = "Sign In"
  },
  computed: {
    currentUser: function () {
      return this.$store.getters.currentUser;
    },
  },
  methods: {
    show_submit() {
      let filled = this.password != "" && this.email != "" && this.name != "";
      if (this.valid && filled) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.snackbar = true;
      }
    },
    // notLoggedIn() {
    //   if (this.currentUser.email == undefined) {
    //     return true
    //   } else {
    //     this.$router.push('/sites')
    //   }
    // },
    submit() {
      this.errorMessage = "";
      let data = {
        user: {
          email: this.email,
          password: this.password,
        },
      };
      this.progressCircle = true;
      this.$store
        .dispatch("login", data)
        .then((response) => {
          this.$router.push("/pages/dashboards/monitoring-portal");
          this.progressCircle = false;
        })
        .catch((error) => {
          Vue.$toast.error(`Incorrect Username or password`, {
            position: "top-right",
          });
          let errors = error.response.data.error;
          this.showError = true;
          this.progressCircle = false;
          for (var key in errors) {
            this.errorMessage += errors[key][0];
          }
        });
    },
  },
};
</script>
